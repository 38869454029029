// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-form {
    margin: 10px 0;
  }
  
  .toggle-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button.open {
    background-color: #28a745; /* Green for "Open" */
  }
  
  .toggle-button.close {
    background-color: #dc3545; /* Red for "Close" */
  }
  
  .toggle-button:hover {
    opacity: 0.8;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/OpenForm.css"],"names":[],"mappings":"AAAA;IACI,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB,EAAE,qBAAqB;EAClD;;EAEA;IACE,yBAAyB,EAAE,oBAAoB;EACjD;;EAEA;IACE,YAAY;EACd","sourcesContent":[".update-form {\n    margin: 10px 0;\n  }\n  \n  .toggle-button {\n    background-color: #007bff;\n    color: white;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 14px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .toggle-button.open {\n    background-color: #28a745; /* Green for \"Open\" */\n  }\n  \n  .toggle-button.close {\n    background-color: #dc3545; /* Red for \"Close\" */\n  }\n  \n  .toggle-button:hover {\n    opacity: 0.8;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
