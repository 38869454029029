// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-form {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    max-width: 400px;
  }
  
  .update-form h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .update-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }

  .delete-menu-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
  }

  .update-submit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
  }
  
  .update-button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/UpdateForm.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,0CAA0C;IAC1C,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;;;IAGE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".update-form {\n    background-color: #f8f9fa;\n    padding: 20px;\n    border-radius: 5px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n    margin: 20px 0;\n    max-width: 400px;\n  }\n  \n  .update-form h2 {\n    margin-bottom: 20px;\n  }\n  \n  .form-group {\n    margin-bottom: 15px;\n  }\n  \n  .form-group label {\n    display: block;\n    margin-bottom: 5px;\n    font-weight: bold;\n  }\n  \n  .form-group input,\n  .form-group textarea,\n  .form-group select {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n  }\n  \n  .update-button {\n    background-color: #007bff;\n    color: white;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n  }\n\n  .delete-menu-button {\n    background-color: #ff4d4d;\n    color: white;\n    border: none;\n    padding: 8px 15px;\n    border-radius: 5px;\n  }\n\n  .update-submit-button {\n    background-color: #4CAF50;\n    color: white;\n    border: none;\n    padding: 8px 15px;\n    border-radius: 5px;\n  }\n  \n  .update-button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
