// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-form {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .create-form h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .create-form input, .create-form select, .create-form button {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .add-truck-button {
    background-color: #28a745;
    color: white;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }
  
  .add-truck-button:hover {
    background-color: #218838;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CreateForm.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,wCAAwC;EAC1C;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,mBAAmB;EACrB","sourcesContent":[".create-form {\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    padding: 20px;\n    margin: 20px auto;\n    max-width: 500px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  }\n  \n  .create-form h3 {\n    margin-bottom: 10px;\n    color: #333;\n  }\n  \n  .create-form input, .create-form select, .create-form button {\n    width: 100%;\n    margin-bottom: 10px;\n    padding: 10px;\n    font-size: 16px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n  }\n  \n  .add-truck-button {\n    background-color: #28a745;\n    color: white;\n    font-weight: bold;\n    border: none;\n    cursor: pointer;\n  }\n  \n  .add-truck-button:hover {\n    background-color: #218838;\n  }\n  \n  .error-message {\n    color: red;\n    margin-bottom: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
