// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-form {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-control {
    margin-bottom: 1rem;
  }
  
  h3 {
    text-align: center;
    color: var(--secondary-color);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AuthForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,wCAAwC;EAC1C;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,6BAA6B;EAC/B","sourcesContent":[".auth-form {\n    max-width: 400px;\n    margin: 2rem auto;\n    padding: 2rem;\n    border-radius: 10px;\n    background-color: white;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .form-control {\n    margin-bottom: 1rem;\n  }\n  \n  h3 {\n    text-align: center;\n    color: var(--secondary-color);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
