// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  background-color: var(--primary-color) !important;
  padding: 10px 20%;
}

.navbar-nav {
  display: flex; /* Ensures horizontal layout */
  flex-direction: row; /* Set direction to horizontal */
  gap: 15px; /* Space between navbar items */
  margin-left: auto; /* Aligns items to the right */
  list-style: none; /* Removes default list styling */
  padding: 0;
}

.nav-item {
  display: flex;
  align-items: center; /* Center-align items vertically */
}

.nav-link, .navbar-brand {
  color: black !important;
  text-decoration: none;
  padding: 5px 10px; /* Optional: Add padding for clickable area */
}

.nav-link:hover {
  color: var(--button-color) !important;
}

.btn-danger {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px; /* Ensures consistent padding with links */
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD;EACjD,iBAAiB;AACnB;;AAEA;EACE,aAAa,EAAE,8BAA8B;EAC7C,mBAAmB,EAAE,gCAAgC;EACrD,SAAS,EAAE,+BAA+B;EAC1C,iBAAiB,EAAE,8BAA8B;EACjD,gBAAgB,EAAE,iCAAiC;EACnD,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB,EAAE,kCAAkC;AACzD;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,iBAAiB,EAAE,6CAA6C;AAClE;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB,EAAE,0CAA0C;EAC7D,eAAe;AACjB","sourcesContent":[".navbar {\n  background-color: var(--primary-color) !important;\n  padding: 10px 20%;\n}\n\n.navbar-nav {\n  display: flex; /* Ensures horizontal layout */\n  flex-direction: row; /* Set direction to horizontal */\n  gap: 15px; /* Space between navbar items */\n  margin-left: auto; /* Aligns items to the right */\n  list-style: none; /* Removes default list styling */\n  padding: 0;\n}\n\n.nav-item {\n  display: flex;\n  align-items: center; /* Center-align items vertically */\n}\n\n.nav-link, .navbar-brand {\n  color: black !important;\n  text-decoration: none;\n  padding: 5px 10px; /* Optional: Add padding for clickable area */\n}\n\n.nav-link:hover {\n  color: var(--button-color) !important;\n}\n\n.btn-danger {\n  background-color: #ff4d4d;\n  color: white;\n  border: none;\n  padding: 5px 10px; /* Ensures consistent padding with links */\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
